import React, { useState } from 'react';
import { DarkModeSwitch } from 'react-toggle-dark-mode';
import useDarkMode from '../Hooks/useDarkMode';

const ToggleThemeSwitcher = () => {
    const [colorTheme, setColorTheme] = useDarkMode();
    const [darkSide, setDarkSide] = useState(colorTheme === 'light' ? true : false);

    const toggleDarkMode = (checked) => {
        setColorTheme(colorTheme);
        setDarkSide(checked);
    };
    return (
        <>
            <DarkModeSwitch checked={darkSide} onChange={toggleDarkMode} size={30} />
        </>
    );
};

export default ToggleThemeSwitcher;

import React from 'react';

const Container = ({ children }) => {
    return (
        <>
            <div className="w-full md:w-[1000px] m-auto p-3">{children}</div>
        </>
    );
};

export default Container;

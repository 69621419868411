import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import ToggleThemeSwitcher from './ToggleThemeSwitcher';

const Header = () => {
    return (
        <>
            <div className="w-full h-20 md:h-28 flex justify-between items-center">
                <div></div>
                <div className="flex gap-3 items-center">
                    <NavLink to="/" className={({ isActive }) => (isActive ? 'active-link' : 'inactive-link')}>
                        Home
                    </NavLink>
                    <NavLink to="about" className={({ isActive }) => (isActive ? 'active-link' : 'inactive-link')}>
                        About
                    </NavLink>
                    <NavLink to="blog" className={({ isActive }) => (isActive ? 'active-link' : 'inactive-link')}>
                        Blog
                    </NavLink>
                    <ToggleThemeSwitcher />
                </div>
            </div>
            <Outlet />
            {/* <div className="w-full h-1 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500"></div> */}
        </>
    );
};

export default Header;
